import React from 'react'


const ServiceReview = () => {
  return (
    <>
       
        <div className="secteitle menu animate__animated animate__fadeInRight">
            <h3> REVIEW YOUR BOOKING </h3>
            <div className="lastvisit">
                    <div className="addons">
                    <div className="addons-right">Location</div><div className="addons-left">Bowery</div>
                </div>
                <div className="addons">
                    <div className="addons-right">Date</div><div className="addons-left">Monday, Dec 26, 2022</div>
                </div>
                    <div className="addons">
                    <div className="addons-right">Time</div><div className="addons-left">Morning</div>
                </div>
            </div>
            <div className="checkboxforfj">
                <div className="form-check fj">
                    <h4>Barber Cut</h4>
                    with Anjeza Lika
                    
                    <div className="priceofservice">+$20.00</div>
                </div>  
                <div className="form-check fj">
                    <h4>Barber Cut</h4>
                    with Anjeza Lika
                    <div className="priceofservice">$120</div>
                </div>  
                <div className="form-check fj">
                    Illuminating Pre Treatment 
                    <div className="priceofservice">$20</div>
                </div> 
                <div className="form-check fj">
                    Illuminating Pre Treatment 
                    <div className="priceofservice">$41</div>
                </div> 
                <div className="form-check fj">
                    <h4>Extension Consultation</h4>
                    with the first available
                    <div className="priceofservice">$0</div>
                </div>                   
            </div>
            <button data-bs-toggle="modal" data-bs-target="#merDetails" className="btn btn-default white border btn-sm waves-effect waves-light selectProfessional"><span> $310.37 </span> Next</button>
        </div>
    </>
  )
}

export default ServiceReview