import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, API_SITE_URL } from "../Helper/ApiConstants";
import { useNavigate,useParams  } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

const ServiceLocations = () => {
	/* all employee */
	const navigate = useNavigate();


	const [locations, setLocations] = useState([]);
	//const [cart_id, setCart_id] = useState([]);
	//const [serviceCategories, setServiceCategories] = useState([]);
	const serviceCategories = [];

	const { business_id } = useParams();
	

	const fetchServiceLocations = () => {

		axios.get(API_BASE_URL+"/list-store-location/"+business_id)
		.then((response) => setLocations(response.data.stores));

	}

	// const generate_cart_id = () => {
	//  	var cart_id = localStorage.getItem(business_id+'_cart_id');

	// 	if( cart_id ==null || cart_id =='undefined' || cart_id ==''){
		
	// 		console.log("if not cart id");
	// 		var data = {business_id:business_id};
	// 		axios.post(API_BASE_URL+"/generate-cart-id",data)
	// 		.then( (response ) => { 
	// 			setCart_id(response.data.cart_token_id);

	// 	 		localStorage.setItem(business_id+'_cart_id', response.data.cart_token_id);

	// 		});
	// 	}
	 	
	// }
	

	// const fetchServiceCategories = (selected_location) => {
	// 	var data = {selected_location:selected_location,business_id:business_id};
	// 	axios.post(API_BASE_URL+"/list-service-categories/",data)
	// 	.then((response) => setServiceCategories(response.data.categories));

	// 	localStorage.setItem(business_id+'_service_categories', JSON.stringify(serviceCategories));
   

	// }

	useEffect(() => {

		var cart_id = localStorage.getItem(business_id+'_cart_id');

		fetchServiceLocations();
		//generate_cart_id();
		
		// console.log(cart_id);

		if( cart_id ===null || cart_id ==='undefined' || cart_id ===''){

			console.log("cart id not exist");

			localStorage.setItem(business_id+'_cart_id', uuidv4());
		}

	},[business_id])


	localStorage.setItem(business_id+'_service_categories', JSON.stringify(serviceCategories));
	localStorage.setItem(business_id+'_store_location', JSON.stringify(locations));
	
	function redirect_to_next_step(selected_location) {
		//fetchServiceCategories(selected_location.id);

		localStorage.setItem(business_id+'_selected_location', JSON.stringify(selected_location) );
		navigate('/booking/business/'+business_id+'/visit-type');
		
	}

	return (
		<div className="secteitle animate__animated animate__fadeInRight">
			<h3>SELECT SERVICE LOCATION</h3>
			<ul className="list-group">

				{ locations.map( location => (

					<li key={location.id} className="list-group-item" onClick={() => redirect_to_next_step(location)}>
				
						<div  className="carryLi">
							<div className="imgCarry">
								<img className="store_log" 
									src={API_SITE_URL+"/storage/uploads/"+location.store_logo} 
									alt={location.store_title} />
							</div>
							<div className="contentCarry">
								<h4>{location.store_city}</h4>
								<div className="undercarry">
									{location.address}<br/>
									{location.store_city}, 
									{location.store_state}
									{location.zip_code}
								</div>
							</div>
							<i className="fas fa-chevron-right"></i>
						</div>	
						
					</li>

				)) }

			</ul>
		</div>

	)
}

export default ServiceLocations