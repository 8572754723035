import React, { useState } from "react";
//import axios from "axios";
//import { useForm } from "react-hook-form";
//import { useNavigate,useParams  } from "react-router-dom";
// import { Capitalize } from '../Helper/Helper'
//import { API_BASE_URL } from "../Helper/ApiConstants";



const Thankyou = () => {
	//const { business_id } = useParams();

	////const [clients, setClients] = useState([]);
	//const [last_page, setLastPage] = useState([]);

	//var rows =[];

	//const navigate = useNavigate();

	return (
		<>
			<div className="back-lnk">
				<div className='backGloble'>
					<i className="fas fa-chevron-left"></i>Back
				</div>

			</div>
			<div className="row">
				<h1 className="thnks_msg">Thank You<br /><span>Your booking is confirm</span></h1>
			</div>

		</>
		
	);
};

export default Thankyou;
