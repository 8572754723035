import React from "react";
import {  Route, Routes } from "react-router-dom";
import './App.css';
//import Header from './Components/Header';
//import ServiceLocations from "./Components/ServiceLocations";
//import Register from "./Components/Register";
import ServiceLocations from "./Components/ServiceLocations";
import ServiceMenu from "./Components/ServiceMenu";
import VisitType from './Components/VisitType';
import ServiceMeunItems from './Components/ServiceMeunItems';
import ServiceMeunItem from './Components/ServiceMeunItem';
import ServiceMeunItemEdit from './Components/ServiceMeunItemEdit';

import ServiceCart from './Components/ServiceCart';
import ServiceCalender from './Components/ServiceCalender';
import ServiceReview from './Components/ServiceReview';
//import ServiceBookingComplete from './Components/ServiceBookingComplete';
import ReviewCart from './Components/ReviewCart';
import Checkout from './Components/Checkout';
import Thankyou from './Components/Thankyou';

//import ServiceSchedule from './Components/ServiceBookingComplete';

import ClientsLogin from './Components/ClientsLogin';
import Layout from './Components/Layout'



function App() {



	//const navigate = useNavigate();
	//const [user, setUser] = useState([]);

	// const { business_id } = useParams();

	// console.log(business_id);

	// const fetchData = () => {
	// 	return axios.get("http://127.0.0.1:8000/api/list-store-location/"+business_id)
    //       .then((response) => setUser(response.data));
	// }

	// useEffect(() => {

	// 	fetchData();
	// },[business_id])




	return (
		<div className="App">
			
			<Routes>
				<Route path="/" element={<Layout />} >
					<Route path="booking/business/:business_id/locations" element={<ServiceLocations />} />
					<Route path="booking/business/:business_id/visit-type" element={<VisitType />} />
					<Route path="booking/business/:business_id/menu" element={<ServiceMenu />} />
					<Route path="booking/business/:business_id/menu/:service_cat" element={<ServiceMeunItems />} />
					<Route path="booking/business/:business_id/menu/:service_cat/:service_id" element={<ServiceMeunItem />} />
					<Route path="booking/business/:business_id/menu/edit/:service_cat/:service_id" element={<ServiceMeunItemEdit />} />
					<Route path="booking/business/:business_id/cart" element={<ServiceCart />} />
					<Route path="booking/business/:business_id/clients-login" element={<ClientsLogin />} />
					<Route path="booking/business/:business_id/time" element={<ServiceCalender />} />
					<Route path="booking/business/:business_id/book" element={<ServiceReview />} />
					<Route path="booking/business/:business_id/booking-review" element={<ReviewCart />} />
					<Route path="booking/business/:business_id/thankyou" element={<Thankyou />} />

					<Route path="booking/business/:business_id/checkout" element={<Checkout />} />

					
				</Route>
			</Routes>
		</div>
		
	);
}

export default App;
