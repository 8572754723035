import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { API_BASE_URL } from "../Helper/ApiConstants";
import { useNavigate, useParams } from "react-router-dom";

const ClientsLogin = () => {

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const [isLoginError, setIsLoginError] = useState('');
	const { business_id } = useParams();

	const navigate = useNavigate();



	useEffect(() => {

		var is_loggedin = (localStorage.getItem('loggedin_customer'));
		if (is_loggedin !=='' && is_loggedin !== null){

			navigate('/booking/business/' + business_id + '/time')

		}

	}, []);

	const loginSubmit = (data) => {
		axios.post(`${API_BASE_URL}/login`, data).then(function (response) {
			console.log(response);

			// if (response.status === 201) {
			// 	toast.error("Bad credentials");
			// 	reset();
			// }
			if (response.status === 200) {

				console.log("sfas asdf asdnfkas dfasd========");
				localStorage.setItem("user_token", response.data.token);
				localStorage.setItem("loggedin_customer",JSON.stringify(response.data.user));
				reset();
				navigate('/booking/business/' + business_id + '/time');
				
				
			}
		}, error => {
			console.log(error.response.data.error);
			if (error.response.status === 401) {
				setIsLoginError(error.response.data.error);
			}
			return error;
		});
	};


	const RegisterHandler = (data) => {
		axios.post(`${API_BASE_URL}/register-customer`, data).then(function (response) {
			console.log(response);

			// if (response.status === 201) {
			// 	toast.error("Bad credentials");
			// 	reset();
			// }
			if (response.status === 200) {
				//toast.success("Login successfully");
				localStorage.setItem("user_token", response.data.token);
				localStorage.setItem("loggedin_customer",JSON.stringify(response.data.user));
				reset();
				
				//localStorage.setItem("current_user_location", JSON.stringify(response.data.defaultLocation));
				//localStorage.setItem("userData", JSON.stringify(response.data.user));
				navigate('/booking/business/' + business_id + '/time')

				
				//window.location.reload();
			}
		});
	};








	return (
		<>
			<div className="nameuserForm animate__animated animate__fadeInRight">
				<div className="addons">
					<div className="addons-right">Login if already registered with us. </div>
				</div>

				<div className="row">
					<div className="col">
						<h1>Login</h1>

						<form onSubmit={  handleSubmit(loginSubmit) } className="containers" noValidate>
							<div className="form-group">
								<label htmlFor="user_email">User Email</label>
								<input type="email" id="email" className="form-control" placeholder="Email"
									{...register("email", {
										required: "email is required",
									})}
								/>
								{errors.email && (
									<span className="text-danger">{errors.email.message}</span>
								)}
							</div>
							<div className="form-group">
								<label htmlFor="password">Password</label>
								<input type="Password" className="form-control" id="password" placeholder="********" 
										{ ...register("password", {
											required: "Password is required",
										}) }
									/>

								{errors.password && (
									<span className="text-danger">{errors.password.message}</span>
								)}

								{(<span className="text-danger">{isLoginError}</span> )}
							</div>
							<button type="submit" className="btn btn-susscess">Login</button> 
							<button type="button" className="btn btn-susscess" 
												onClick={() => navigate('/booking/business/' + business_id + '/time')}>Guest Checkout</button> 
						</form>

					</div>
					{/*<div className="col">
						<h1>Register</h1>

						<form onSubmit={  handleSubmit(RegisterHandler) } className="containers" noValidate>

							<div className="form-group">
								<label htmlFor="first_name">First Name</label>
								<input type="text" id="first_name" className="form-control" placeholder="First Name"
									{...register("first_name", {
										required: "First name is required",
									})}
								/>
								{errors.first_name && (
									<span className="text-danger">{errors.first_name.message}</span>
								)}
							</div>

							<div className="form-group">
								<label htmlFor="last_name">Last Name</label>
								<input type="text" id="last_name" className="form-control" placeholder="Last Name"
									{...register("last_name")}
								/>
								
							</div>

							<div className="form-group">
								<label htmlFor="user_email">User Email</label>
								<input type="email" id="email" className="form-control" placeholder="Email"
									{...register("email", {
										required: "email is required",
									})}
								/>
								{errors.email && (
									<span className="text-danger">{errors.email.message}</span>
								)}
							</div>
							<div className="form-group">
								<label htmlFor="password">Password</label>
								<input type="Password" className="form-control" id="password" placeholder="********" 
										{ ...register("password", {
											required: "Password is required",
										}) }
									/>

								{errors.password && (
									<span className="text-danger">{errors.password.message}</span>
								)}

							</div>
							<button type="submit" className="selectProfessional">Register</button> 
						</form>

					</div>*/}


				</div>

				{/*<form onSubmit={  handleSubmit(loginSubmit) } className="containers" noValidate>
					<div className="form-group">
						<label htmlFor="user_email">User Email</label>
						<input type="email" id="email" className="form-control" placeholder="Email"
							{...register("email", {
								required: "email is required",
							})}
						/>
						{errors.email && (
							<span className="text-danger">{errors.email.message}</span>
						)}
					</div>
					<div className="form-group">
						<label htmlFor="password">Password</label>
						<input type="Password" className="form-control" id="password" placeholder="********" 
								{ ...register("password", {
									required: "Password is required",
								}) }
							/>

						{errors.password && (
							<span className="text-danger">{errors.password.message}</span>
						)}

						{(<span className="text-danger">{isLoginError}</span> )}
					</div>
					<button type="submit" className="selectProfessional">Login</button> 
					<button type="button" className="selectProfessional">Continue as Guest</button>
				</form>*/}
			</div>
		</>
	)
}

export default ClientsLogin