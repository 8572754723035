	import React, { useEffect, useState } from "react";
	import axios from "axios";
	import { useForm } from "react-hook-form";

	import { API_BASE_URL } from "../Helper/ApiConstants";
	import { useNavigate,useParams  } from "react-router-dom";

	import { decode } from 'js-base64';


	const ServiceMeunItem = () => {

		//const [location, setLocation] = useState([]);
		const navigate = useNavigate();
		const [service,setService] = useState([]);
		//const [service_addons,setServiceAddOns] = useState([]);
		const [added_cart_addons,setAddedCartAddons] = useState([]);

		const [added_cart_addons_list, setAddedCartCddonsList] = useState([]);
		//const [categoryName,setCategoryName] = useState([]);
		const [serviceAddons,setServiceAddons] = useState([]);
		const [specialits,setSpecialits] = useState([]);
		const [specialits_min_max_price,setSpecialitsMinMaxPrice] = useState([]);
		const [visit_type,setVisitType] = useState([]);



		const [ service_for1,setServiceFor] = useState([]);

		const [isGuestVisible, setIsGuestVisible] = useState(false);

		const { business_id } = useParams();
		const { service_id } = useParams();
		const { service_cat } = useParams();
		//const service_staff_ids = [];

		const decoded_id = service_id;
		
		//console.log(decode(service_id),'service_id==');
		const {
			register,
			handleSubmit,
			reset,
			//control,
			getValues,
			formState: {  },
		} = useForm({
			defaultValues: {
						      selected_staff_id: "0",
						      service_for: "me"
						    },
			mode: "onChange",
		});

		const updateHandler = (data1) => {

			var cart_id = localStorage.getItem(business_id+'_cart_id');
			var location_id = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) );
			var service_id = JSON.parse( (localStorage.getItem(business_id+'_selected_service')) );
			var service_cat_id = JSON.parse( (localStorage.getItem(business_id+'_selected_service_cat')) );
			var visit_type = localStorage.getItem(business_id+'_visit_type');
			var service_for = getValues('service_for');
			var guest_name = getValues('guest_name');
			//var added_cart_addons1 = added_cart_addons;


			//console.log('------ppp',added_cart_addons1);
			
			if(service_for == "New Guest"){

				if(guest_name!=''){
					//setServiceFor([...service_for1, guest_name]);
					service_for = guest_name;

				}
				
			}

			// if(service_for >= 2){

			// 	if(guest_name!=''){

			// 		setServiceFor([...service_for1, guest_name]);
			// 		service_for = guest_name;

			// 	}
				
			// }

			

			var data = { business_id:business_id, 
						
						cart_id				: cart_id,
						location_id 		: location_id.id,
						service_id  		: service_id.id,
						service_type 		: 'service',
						cat_slug 			: service_cat,
						decoded_id 			: decoded_id,
						service_cat_id 		: service_cat_id.id,
						selected_staff_id 	: data1.selected_staff_id,
						staff_price 		: data1.staff_price,
						service_for 		: service_for,
						visit_type 			: visit_type,
						added_cart_addons   : added_cart_addons,
					};


			console.log('===',data);

			axios
				.post(`${API_BASE_URL}/add-item-to-cart`, data)
				.then((response) => {

					if (response.status === 200) {
						navigate('/booking/business/'+business_id+'/cart');
						reset();
						//ListServices();

					}
				});

		};


		const capitalizeFirstLowercaseRest = (str) => {
			if(str !='' || str != undefined){
				return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
			}
			return str;
		}

		const get_added_cart_addons = () =>{

			var cart_id = localStorage.getItem(business_id+'_cart_id');
			var location_id = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) );


			// console.log(decode(service_id));
			// console.log('llllll');

			var data = { 
						cart_id:cart_id,
						service_id:decode(service_id),
						location_id:location_id.id
					};

				axios.post(API_BASE_URL+"/get-added-cart-addons",data)
					.then((response) => {
						setAddedCartAddons(response.data.addons);

						var addons = response.data.addons;
						setAddedCartCddonsList( { ...added_cart_addons_list, addons });

						console.log(response.data.service_for);
						setServiceFor(response.data.service_for);
					});


		}

		const set_selected_addons = (addon, addon_index=0, is_remove=0 ) => {
			if(is_remove == 1){

				setAddedCartAddons(added_cart_addons.filter((_, index) => index !== addon_index));

			} else {
				setAddedCartAddons([...added_cart_addons,addon]);

			}

		}

		const update_service_addon_from_list = (addon_index, addon=[], is_remove=0) => {
			if(is_remove == 1){
				setServiceAddons([...serviceAddons,addon]);

			} else{
				setServiceAddons(serviceAddons.filter((_, index) => index !== addon_index));

			}

		}

		const add_addon = (addon,addon_index) => {

			set_selected_addons(addon);
			update_service_addon_from_list(addon_index);


			// var cart_id = localStorage.getItem(business_id+'_cart_id');
			// var visit_type = localStorage.getItem(business_id+'_visit_type');
			// var location_id = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) );
			// //var service_id1 = JSON.parse( (localStorage.getItem(business_id+'_selected_service')) );
			// var service_cat_id = JSON.parse( (localStorage.getItem(business_id+'_selected_service_cat')) );
			// var service_for = getValues('service_for');
			// var guest_name = getValues('guest_name');

			// if(service_for == 0){
			// 	service_for = 'me';
			// }

			// if(service_for == 1){
			// 	service_for = guest_name;
			// }

			// if(service_for > 1){
			// 	service_for = 'testwww';
			// }



			// var data = { business_id 	: business_id, 
			// 			addon 			: addon,
			// 			service_type	: 'addon',
			// 			cat_slug 		: service_cat,
			// 			decoded_id 		: decoded_id,
			// 			cart_id 		: cart_id,
			// 			location_id 	: location_id.id,
			// 			service_id 		: decode(service_id),
			// 			service_cat_id 	: service_cat_id.id,
			// 			service_for 	: service_for,
			// 			visit_type 		: visit_type,
			// 		};


			// console.log(data);

			// axios.post(API_BASE_URL+"/add-item-to-cart", data )
			// 	.then((response) => {
			// 		set_services_data();
			// 		get_added_cart_addons();
					
			// 	});
		}

		const remove_cart_added_addon = (addon, addon_index) =>{
			var is_remove = 1;
			set_selected_addons(addon, addon_index, is_remove );


			update_service_addon_from_list(addon_index, addon, is_remove);

			//var data = { addon_id:addon_id,cart_id:cart_id,addon_service_id:decode(service_id) };

			// axios.get(API_BASE_URL+"/remove-cart-added-addon/"+id)
			// 	.then((response) => {
			// 		set_services_data()
			// 		get_added_cart_addons();
			// 		//console.log("sdfsdfasdfasd");
					
			// 	});
		}

		const handleOptionChange = (data) => {

			var service_for = data.target.value;


			console.log(service_for,'========');

			if(service_for == "New Guest"){
			
				setIsGuestVisible(true);
			} else {
				setIsGuestVisible(false);

			}
			

		}


		const set_services_data = () => {

			var servicesq = JSON.parse(localStorage.getItem(business_id+'_selected_service'));
			var selected_location = JSON.parse(localStorage.getItem(business_id+'_selected_location'));

			var cart_id = localStorage.getItem(business_id+'_cart_id');
			var visit_type = localStorage.getItem(business_id+'_visit_type');

			setService(servicesq);
			setVisitType(visit_type);


			var data = { business_id:business_id, 
						service_id:decode(service_id), 
						location_id:selected_location.id,
						cart_id:cart_id
					};

			axios.post(API_BASE_URL+"/list-service-addons",data)
			.then((response) => setServiceAddons(response.data.addons));

			axios.post(API_BASE_URL+"/list-specialists",data)
			.then((response) => { 
				setSpecialits(response.data.employee); 
				setSpecialitsMinMaxPrice(response.data.min_max_price) 
			});

			//setCategoryName(servicesq.category_name)
			//fetchServiceLocations();
			//console.log(business_id+'_selected_service_cat');
	   

		}

		useEffect(() => {
			set_services_data();
			get_added_cart_addons();
			
		},[]);

		//console.log(specialits);


		// function redirect_to_next_step(visit_type) {
		// 	localStorage.setItem(business_id+'_visit_type',visit_type)
		// 	navigate('/booking/businesses/'+business_id+'/menu');
		// }

		return (
			<>
			<div className="back-lnk">
				<div className='backGloble' onClick={() => navigate(-1)}><i className="fas fa-chevron-left"></i>Back</div>
			</div>
			
				<div className="secteitle menu animate__animated animate__fadeInRight">
					<h3>{service.service_title}</h3>
					<p>{service.service_description}</p>
					<div className="pricenote">All prices shown are starting prices and may increase at your artist's discretion.</div>

					{ visit_type ==='group' &&
						<>

						{console.log(service_for1)}

							<div className="visit_type">
								<h3>Select Service For</h3>
								<select className="form-select" name="service_for" {...register("service_for")} 
											onChange={handleOptionChange}>
									
									{ service_for1.map( (for_service,i) => (

										<option key={i} value={for_service}>{for_service}</option>

									))}
									
								</select>

							</div>

	  						{ isGuestVisible ? (
								<div className="guest-data">
									<div className="mb-3">
										<label htmlFor="guest_name" className="form-label">Guest Name</label>
										<input type="text" className="form-control" placeholder="Guest Name" id="guest_name" name="guest_name" {...register("guest_name")} />

									</div>
								</div> ) : null 
	  						
	  						}


						</>



					}


					{added_cart_addons.length >0  && (
						<>
							<div className="selected-addons">

								<div className="addons">
									<div className="addons-right">Selected ADD ONS</div>
								</div>
								{ added_cart_addons.map( (cart_addon, i) => (
									<div className="selected-addon" data={cart_addon.service_id} key={i}>

										<i className="fas fa-trash" onClick={ () => remove_cart_added_addon(cart_addon,i) }></i>
										<h4>{capitalizeFirstLowercaseRest(cart_addon.service_title)}</h4>

										<div className="addons-desc">{cart_addon.service_description}</div>
										<div className="priceofservice">+${cart_addon.service_price}</div>
									</div>
								))}
							</div>
						</>
					)}
					

					{serviceAddons.length >0  && (
						<>
						<div className="addons-list">
							<div className="addons">
								<div className="addons-right">ADD ONS</div>
								<div className="addons-left">Available to Add</div>
							</div>
							<div className="checkboxforfj">
								{ serviceAddons.map( (addons,i) => (
									<div className="addons" key={addons.id}  data={ addons.id } onClick={ () => add_addon(addons,i) }>

										<i className="fas fa-plus-circle" ></i>
										<h4>{capitalizeFirstLowercaseRest(addons.service_title)}</h4>
										<div className="addons-desc">{addons.service_description }</div>
										<div className="priceofservice">+${addons.service_price}</div>
									</div>
								)) }

							</div>
						</div>
						</>
					)}
					
					
					<button data-bs-toggle="modal" data-bs-target="#merDetails" className="btn btn-default selectProfessional">Select professional</button>
				</div>

				{/* <!-- OPEN MODEL --> */}
				<div className="modal modalWrap slidePanel fade" id="merDetails"  style={{display:"none"}}>
					<div className="panel-dialog modal-dialog modal-dialog-scrollable panel-lg">
						<div className="panel-content modal-content">
							<div className="close-panel text-align-center align-middle">
								<button className="btn btn-simple p-0 white waves-effect waves-light" data-bs-dismiss="modal" aria-label="Close">
									<div className="fal fa-times"></div> Close
								</button>
							</div>
							<div className="modal-body panelBody">
								<div className="secteitle menu">
									<h3>SELECT A PROFESSIONAL</h3>
									{/* <!-- <p>For those clipper fades or scissor over comb cuts on super short hair.</p>
									<div className="addons"><div className="addons-right">ADD ONS</div>
									<div className="addons-left">choose up to 3</div></div> --> */}
									<div className="checkboxforfj">
										<form onSubmit={handleSubmit(updateHandler)} method="POST">
											<div className="form-check fj" key={"specialist_any"}>
												<label className="form-check-label" htmlFor="flexRadioDefault_any">

													<input 
														className="form-check-input" 
														 
														{...register("selected_staff_id")} 
														value="0"
														type="radio" 
														id="flexRadioDefault_any" 

														
													/>
													<h4><i className="far fa-user"></i>Any First Available</h4>
													Hair Styler
													
												</label>

												<div className="priceofservice">
													+ ${ specialits_min_max_price.min_price } - ${ specialits_min_max_price.max_price }
												</div>
											</div>

											{ specialits.map( (specialist, i) => (
												<div className="form-check fj" key={"specialist_"+i}>
													<label className="form-check-label" 
														htmlFor={"flexRadioDefault"+specialist.id+i}>

														<input type="hidden" 
																	name="staff_price" 
																	{...register("staff_price")}
																	value={specialist.service_prices}
																	/>
														
														<input 
															className="form-check-input" 
															 
															{...register("selected_staff_id")} 
															value={specialist.id}
															type="radio" 
															id={"flexRadioDefault"+specialist.id+i} 
														/>
														<h4><i className="far fa-user"></i> 
															{ capitalizeFirstLowercaseRest( specialist.first_name+' '+specialist.last_name ) } 
														</h4> 
														
														{capitalizeFirstLowercaseRest(specialist.staff_roles)}
													</label>

													<div className="priceofservice">+ ${ specialist.service_prices }</div>
												</div>
											)) }
									
											<button type="submit" data-bs-dismiss="modal" className="btn waves-light selectProfessional">Add</button>

										</form>
									</div>    
								</div>
									
							</div>
						</div>
					</div>
				</div>

			</>
		)
	}

	export default ServiceMeunItem