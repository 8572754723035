import React, { useEffect, useState } from "react";
import axios from "axios";
//import { useForm, Controller } from "react-hook-form";
import { useNavigate,useParams  } from "react-router-dom";
//import { Capitalize } from '../Helper/Helper'
import { API_BASE_URL } from "../Helper/ApiConstants";

const ServiceCart = () => {

	const { business_id } = useParams();
	const [cart_items, setCartItems] = useState([]);
	const [cart_total, setCartTotal] = useState('');
	const navigate = useNavigate();

	// const navigate_to_menu = () =>{
	// }


	const get_cart_services = ()=> {

		var cart_id = localStorage.getItem(business_id+'_cart_id');
		var location_id = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) );

		var data = { cart_id:cart_id,location_id:location_id.id };

		axios.post(API_BASE_URL+"/get-added-cart-services",data)
			.then((response) => {

				//console.log(response.data.cart_services);
				setCartItems(response.data.cart_services);
				setCartTotal(response.data.sum);
				localStorage.setItem( business_id+'_cart_items', JSON.stringify(response.data.cart_services) );

			});


	}

	const remove_cart_added_service = (cart_item) => {

		var data = { cart_id : cart_item.id, cart_item_type : cart_item.cart_item_type, service_id : cart_item.service_id };


		console.log(data);

		axios.post(API_BASE_URL+"/remove-cart-service",data)
			.then( (response) => {
				get_cart_services();
				//set_services_data()
				//get_added_cart_addons();
				//console.log("sdfsdfasdfasd");
			});
	}

	const service_Staff_member = (staff_member) => {

		//console.log(staff_member['first_name'],"-------------============");
		var staff_member_name = 'Any First Available';
		
		if(staff_member) {
			staff_member_name = staff_member['first_name'] +' '+  staff_member['last_name'];
		}

		return staff_member_name;

	}

	useEffect(() => {
		get_cart_services();
		
	},[]);


  return (
	<>
	<div className="back-lnk">
			<div className='backGloble' onClick={() => 	navigate('/booking/business/'+business_id+'/menu')}>
				<i className="fas fa-chevron-left"></i>Back
			</div>
		</div>
	  
	<div className="secteitle menu animate__animated animate__fadeInRight">
		
		<div className="addons">
			<div className="addons-right"> YOUR CART </div>{/* <div className="addons-left"></div> */}
		</div>
		<div className="checkboxforfj">

			{ cart_items.length === 0  && (
				<div className="empty-cart">
					<div>
						<div className="alert alert-warning">You have not selected any service.</div>
						<button type="button" className="btn btn-secondary" onClick={() => 	navigate('/booking/business/'+business_id+'/menu')}>
						<i className="fas fa-plus"></i>Add Service</button>
					</div>
					
					
				</div>
			)}


			{ cart_items.length >0  && (
				<>
					<div className="selected-addons">


						{ cart_items.map( (cart_item, i) => (
							<div className="selected-addon" data={cart_item.service_id} key={i} >
								{console.log(cart_items)}
								<i className="fas fa-trash" 
									onClick={ () => remove_cart_added_service(cart_item) }></i>

								<div className="service" onClick={ () => navigate('/booking/business/' + business_id + '/menu/edit/' + cart_item.cat_slug + '/' + cart_item.decoded_id)}>
									<h4>{ (cart_item.service_title) }</h4>
									<div className="addons-desc">{cart_item.service_description}</div>
									<div className="staff_name">{service_Staff_member(cart_item.staff_members) }</div>
									{ cart_item.service_for && (<div className="service_for"> For {cart_item.service_for }</div>)}
									
									<div className="priceofservice">+${cart_item.service_price}</div>
								</div>

								{ cart_item.service_addons && (
									<>
									<div className='service_addons'>

										
							            { cart_item.service_addons.map((item, index) => (

							            	<div className="service" onClick={ () => navigate('/booking/business/' + business_id + '/menu/edit/' + cart_item.cat_slug + '/' + cart_item.decoded_id)}>
												<h4>{ (item.service_title) }</h4>
												<div className="addons-desc">{item.service_description}</div>
												<div className="staff_name">{service_Staff_member(item.staff_members) }</div>
												{ item.service_for && (<div className="service_for"> For {item.service_for }</div>)}
												
												<div className="priceofservice">+${item.service_price}</div>
											</div>
					
							            ))}
								        
									</div>
									</>
								)}

							</div>
						))}

						<div className="cart-total"> Total ${ cart_total }</div>
					</div>

					<div className="back-lnk">
						<div className='backGloble' onClick={() => navigate('/booking/business/'+business_id+'/menu')}>
							<i className="fas fa-plus"></i>Add More</div>

						<button className="btn btn-success" onClick={() => navigate('/booking/business/'+business_id+'/clients-login')}>Next</button>
					</div>
				</>

			)}
		   
		</div>

	</div>
	</>
  )
}

export default ServiceCart