import React from "react";
import { APP_SITE_URL } from "../Helper/ApiConstants";
// import { Link } from "react-router-dom";
import { useNavigate,useParams  } from "react-router-dom";


const Header = () => {

	const navigate = useNavigate();
	const { business_id } = useParams();

	return (
		<div className="fullsec_fj">
			<div className="cart-icon">
				<i className="fal fa-shopping-basket" onClick={()=>navigate('/booking/business/'+business_id+'/cart')}></i>
			</div>
			
			<div className="fjlogo">
				<img src={APP_SITE_URL+"/images/logo-medium.png"} alt="Fox and Jane Salon" />
			</div> 
		</div>
	)
}

export default Header