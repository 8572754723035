import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../Helper/ApiConstants";
import { useNavigate,useParams  } from "react-router-dom";

const VisitType = () => {

	//const [location, setLocation] = useState([]);
	const navigate = useNavigate();
	
	const { business_id } = useParams();
	const [serviceCategories, setServiceCategories] = useState([]);

	function redirect_to_next_step(visit_type) {
		
		localStorage.setItem(business_id+'_visit_type',visit_type)
		navigate('/booking/business/'+business_id+'/menu');
	}

	const fetchServiceCategories = (selected_location) => {
		
		var data = { selected_location: selected_location, business_id: business_id, dd: 'dddd' };

		axios.post(API_BASE_URL + "/list-service-categories",data)
		.then((response) => setServiceCategories(response.data.categories));		

	}

	useEffect(() => {
		var selected_location = JSON.parse(localStorage.getItem(business_id+'_selected_location') );
		fetchServiceCategories(selected_location.id);

	},[business_id])

	
	localStorage.setItem( business_id+'_service_categories', JSON.stringify(serviceCategories) );


	return (
		<>
			<div className='backGloble' onClick={() => navigate(-1)}>
				<i className="fas fa-chevron-left"></i>back
			</div>
			<div className="secteitle animate__animated animate__fadeInRight">
				<h3>APPOINTMENT PREFERENCE</h3>
				<ul className="list-group">
					<li className="list-group-item">
						<div className="carryLi" onClick={ () => redirect_to_next_step('individual') }>
							<div className="contentCarry"> 
								<h4> Individual Appointment</h4>
								<div className="undercarry">Schedule services for yourself</div>
							</div>
							<i className="fas fa-chevron-right"></i>
						</div>
					</li>
					<li className="list-group-item">
						<div className="carryLi">
							<div className="contentCarry" onClick={ () => redirect_to_next_step('group') } >
								<h4>Group Appointments</h4>
								<div className="undercarry">Schedule services for yourself, friends and family</div>
							</div>
							<i className="fas fa-chevron-right"></i>
						</div>
					</li>
					{/* <li className="list-group-item">
						<div className="carryLi">
							<div className="contentCarry">
								<h4>Purchase a Gift Card</h4>
								<div className="undercarry">Purchase a gift card for future visits</div>
							</div>
							<i className="fas fa-chevron-right"></i>
						</div>
					</li> */}
				</ul>
			</div>
		</>
	)
}

export default VisitType