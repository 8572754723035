import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'

const Layout = () => {
  return (
  <>
    <Header />
    {/* <div>Layout</div> */}
    <Outlet />
  </>
  
  )
}

export default Layout