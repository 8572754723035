import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate,useParams  } from "react-router-dom";
// import { Capitalize } from '../Helper/Helper'
import { API_BASE_URL } from "../Helper/ApiConstants";



const ReviewCart = () => {

	const { business_id } = useParams();
	const [cart_items, setCartItems] = useState([]);
	const [cart_total, setCartTotal] = useState('');
	const [location_name, setLocationName] = useState('');
	const [store_title, setStoreTitle] = useState('');

	const [booking_date, setBookingDate] = useState('');
	const [booking_time, setBookingTime] = useState('');
	//const [booking_amt, setBookingAmt] = useState('');
	const [visit_type, setVisitType] = useState('');
	//const [is_loggedin, setIsLoggedin] = useState('');

	var selected_service_ids = [];

	const {
		register,
		handleSubmit,
		reset,
		//control,
		setValue,
		//getValues,
		formState: { errors },
	} = useForm({
		mode: "onChange",
	});

const options = {
    // passing the client secret obtained from the server
    clientSecret: 'sk_test_KwAQdk26xfkUUwQaUBG09At0',
  };

	const updateHandler = (data1) => {

		var location_id = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) );
		console.log(location_id);
		var cart_id = localStorage.getItem(business_id+'_cart_id');
		var selected_time = localStorage.getItem(business_id+'_selected_time');
		var selected_date = localStorage.getItem(business_id+'_selected_date');
		console.log(data1);

		var data = { business_id:business_id,
						location_id: location_id.id, 
						cart_id: cart_id,
						cart_total:cart_total,
						selectedTime: selected_time, 
						selectedDate: selected_date,
						booking_data: data1
					};


		console.log(data);

		

		axios
			.post(`${API_BASE_URL}/confirm-booking`, data)
			.then((response) => {
					console.log(response.data);

				if (response.data.status === 'success') {
					reset();
					remove_local_storage_date(business_id);
					navigate('/booking/business/'+business_id+'/thankyou');
					//ListServices();
				}
			});

	};
	
	const navigate = useNavigate();


	const remove_local_storage_date = (business_id) => {

		localStorage.removeItem(business_id+'_cart_id');
		localStorage.removeItem(business_id+'_cart_items');
		localStorage.removeItem(business_id+'_selected_date');
		localStorage.removeItem(business_id+'_selected_time');
	}
	

	const get_cart_services = () => {

		var cart_id = localStorage.getItem(business_id+'_cart_id');
		var location_id = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) );

		var data = { cart_id:cart_id,location_id:location_id.id, review_cart:1 };

		axios.post(API_BASE_URL+"/get-added-cart-services",data)
			.then((response) => {
				//setBookingAmt();

				//console.log(response.data.cart_services);
				setCartItems(response.data.cart_services);
				setCartTotal(response.data.sum);
				localStorage.setItem( business_id+'_cart_items', JSON.stringify(response.data.cart_services) );

			});


	}

	// const remove_cart_added_service = (id) =>{

	// 	//var data = { addon_id:addon_id,cart_id:cart_id,addon_service_id:decode(service_id) };

	// 	axios.get(API_BASE_URL+"/remove-cart-service/"+id)
	// 		.then((response) => {
	// 			get_cart_services();
	// 			//set_services_data()
	// 			//get_added_cart_addons();
	// 			//console.log("sdfsdfasdfasd");
				
	// 		});
	// }

	const service_Staff_member = (staff_member) => {

		//console.log(staff_member['first_name'],"-------------============");
		var staff_member_name = 'Any First Available';
		
		if(staff_member) {
			staff_member_name = staff_member['first_name'] +' '+  staff_member['last_name'];
		}

		return staff_member_name;

	}

	useEffect(() => {

		var store_title = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) ).store_title;
		var location_name1 = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) ).store_city;
		var loggedin_user_info = JSON.parse( localStorage.getItem('loggedin_customer')) ;


		if (loggedin_user_info) {
			console.log(loggedin_user_info);
			console.log("dddddsfasdfasdfasd");

			setValue("first_name", loggedin_user_info.first_name);
			setValue("last_name", loggedin_user_info.last_name);
			setValue("contact_number", loggedin_user_info.contact_number);


			//navigate('/booking/business/' + business_id + '/time')

			// setValue([
	        //     { first_name: loggedin_user_info.first_name }, 
	        //     { last_name: loggedin_user_info.last_name }
	        // ]);

		} else {
			setValue("first_name", '');
			setValue("last_name", '');
			setValue("contact_number", '');
		}

		setBookingDate(localStorage.getItem(business_id+'_selected_date'));
		setBookingTime(localStorage.getItem(business_id+'_selected_time'));	
		setVisitType( localStorage.getItem(business_id+'_visit_type') );

		setLocationName(location_name1);
		setStoreTitle(store_title);

		get_cart_services();
		
	},[business_id]);



	

  return (
	<>
	<div className="back-lnk">
		<div className='backGloble' onClick={() => navigate('/booking/business/'+business_id+'/menu')}>
			<i className="fas fa-chevron-left"></i>Back
		</div>
	</div>
	  
	<div className="secteitle menu animate__animated animate__fadeInRight">
		
		<div className="addons">
			<div className="addons-right"> Review Your Bookings</div>
		</div>
		<div className="checkboxforfj">

			{cart_items.length >0  && (
				<>
					<form onSubmit={handleSubmit(updateHandler)} method="POST">
						<div className="selected-addons">

							<div className="locations">Locations {store_title} <span> {location_name} </span>
								<button className="btn btn-warning" onClick={() => navigate('/booking/business/'+business_id+'/locations')}>Edit</button> 

							</div>
							<div className="locations">Date {booking_date}
								<button className="btn btn-warning" onClick={() => navigate('/booking/business/'+business_id+'/time')}>Edit</button>
							</div>
							<div className="locations">Time {booking_time}
								<button className="btn btn-warning" onClick={() => navigate('/booking/business/'+business_id+'/time')}>Edit</button>
							</div>							

							<div className="">Items</div>

								{ cart_items.map( (cart_item, i) => (
									<div className="selected-addon" data={cart_item.service_id} key={i} >
										

										<div className="service">
											<h4>{(cart_item.service_title)}</h4>
											<div className="addons-desc">{ cart_item.service_description }</div>
											<div className="staff_name">{ service_Staff_member(cart_item.staff_members) }</div>

											{ visit_type !=='individual' && (
												<div className="service_for"> For { cart_item.service_for }</div>

											)}

											<div className="priceofservice"> +${ cart_item.service_price }</div>
										</div>

									</div>
								))}

								<input type="hidden" value = {selected_service_ids} {  ...register("selected_service_ids") } />
							<div className="cart-total">Total ${ cart_total }</div>
						</div>

						<div className="row">
							<div className="col">

								<div className="form-group">
									<label htmlFor="first_name">First Name</label>
									<input type="text" className="form-control" id="first_name" placeholder="First Name" {...register("first_name", {
													required: "First Name is required",
												})}/>

									{errors.first_name && (
										<span className="text-danger">{errors.first_name.message}</span>
									)}

								</div>

								<div className="form-group">
									<label htmlFor="email">Email.</label>
									<input type="email" id="email" className="form-control" placeholder="Email"
												{...register("email", {
													required: "email is required",
												})}
											/>
										{errors.email && (
											<span className="text-danger">{errors.email.message}</span>
										)}
								</div>

								<div className="form-group">
									<label htmlFor="phone">Phone No.</label>
									<input type="text" className="form-control" id="phone" placeholder="Phone No."  {...register("contact_number")}/>
								</div>

								

							</div>

							<div className="col">
								<div className="form-group">
									<label htmlFor="last_name">Last Name</label>
									<input type="text" className="form-control" id="last_name" placeholder="Last Name" {...register("last_name")}/>
								</div>

								<div className="form-group">
									<label htmlFor="password">Password</label>
									<input type="password" className="form-control" id="password" placeholder="********" 
												{ ...register("password", {
													required: "Password is required",
												}) }/>

									{errors.password && (
										<span className="text-danger">{errors.password.message}</span>
									)}
								</div>

								
								<div className="mb-3 form-check">
									<input type="checkbox" className="form-check-input" name="news_subscribe" id="news_subscribe" {...register("is_subscribed")} />
									<label className="form-check-label" htmlFor="news_subscribe">Text me news and special offers.</label>
									<div id="emailHelp" className="form-text">
										By signing up via text, you agree to receive recurring automated marketing messages at the phone number provided.
										Reply STOP to unsubscribe. Reply HELP for help. Msg & data rates may apply.
									</div>
								</div>
									
							</div>

							<textarea className="form-control" {...register("comments")}></textarea>

						</div>
						<div className="dd"> </div>
						<div className="back-lnk">
							<button type="submit" className="btn  btn-success waves-light selectProfessional">Confirm Booking</button>

						</div>
					</form>
	
				</>

			)}
		   
		</div>
		

	</div>
	</>
  )
}

export default ReviewCart