import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {  API_SITE_URL } from "../Helper/ApiConstants";
import { useNavigate,useParams } from "react-router-dom";
import { encode } from 'js-base64';
const ServiceMeunItems = () => {

	const navigate = useNavigate();
	const [services,setServices] = useState([]);
	const [categoryName,setCategoryName] = useState([]);
	const { business_id } = useParams();



	const set_services_data = () => {

		var servicesq = JSON.parse(localStorage.getItem(business_id+'_selected_service_cat'));
		setServices(servicesq.services);
		setCategoryName(servicesq.category_name)
		//fetchServiceLocations();
		//console.log(business_id+'_selected_service_cat');

		//console.log(services,"eeeeeeeeeee");
   

	}

	useEffect(() => {
		set_services_data();
		
	},[]);
	console.log(encode(10));

	function redirect_to_next_step(service) {

		//console.log(service);

		// console.log(encode(service.id));
		// console.log(decode('OA=='));
		var selected_cat = JSON.parse(localStorage.getItem(business_id+'_selected_service_cat'));
		//console.log(selected_cat.cat_slug);

		localStorage.setItem( business_id+'_selected_service', JSON.stringify(service) );

		navigate('/booking/business/'+business_id+'/menu/'+selected_cat.cat_slug+'/'+encode(service.id));
	}

  return (
	<>
		<div className="back-lnk">
			<div className='backGloble' onClick={() => navigate(-1)}><i className="fas fa-chevron-left"></i>Back</div> {categoryName}
		</div>
				
		<div className="secteitle menu animate__animated animate__fadeInRight ">
			
			<ul className="list-group">

				{ services.map( service => (
					<li key={service.id}  className="list-group-item" onClick={() => redirect_to_next_step(service)}>
						<div className="carryLi">
							<div className="contentCarry">
								<h3>{ service.service_title }</h3>
							</div>
							<i className="fas fa-chevron-right"></i>
						</div>
					</li>
				)) }

				{/*<li className="list-group-item">
					<a href="#" className="carryLi">
						<div className="contentCarry">
							<h3>Short Advanced/ Pixie Cut</h3>
						</div>
						<i className="fas fa-chevron-right"></i>
					</a>
				</li>
				*/}
			</ul>
		</div>

	</>
  )
}

export default ServiceMeunItems