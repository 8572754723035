import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ServiceMenu = () => {

	//const [location, setLocation] = useState([]);
	const navigate = useNavigate();
	
	const { business_id } = useParams();
	const [serviceCategories, setServiceCategories] = useState([]);


	function redirect_to_next_step(service_cat) {
		localStorage.setItem( business_id+'_selected_service_cat', JSON.stringify(service_cat) );
		localStorage.setItem( business_id+'_category_services', JSON.stringify(service_cat.services) );

		navigate('/booking/business/'+business_id+'/menu/'+service_cat.cat_slug);
	}

	useEffect(() => {

		var service_categories =JSON.parse(localStorage.getItem(business_id+'_service_categories'));
		setServiceCategories(service_categories);


	},[business_id])

  return (
	<>
		<div className="back-lnk">
			<div className='backGloble' onClick={() => navigate(-1)}><i className="fas fa-chevron-left"></i>Back</div>
		</div>
		
		<div className="secteitle menu animate__animated animate__fadeInRight">
			<h3>MENU</h3>
			<ul className="list-group">

				{ serviceCategories.map( category => (

					<li key={category.id} className="list-group-item">
						<div className="carryLi" onClick={() => redirect_to_next_step(category)}>
							<div className="contentCarry"><h3>{category.category_name}</h3></div>
							<i className="fas fa-chevron-right"></i>
						</div>
					</li>

				)) }
			</ul>
		</div>

	</>
  )
}

export default ServiceMenu