import React, { useState,useEffect } from "react";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import axios from "axios";
import { API_BASE_URL } from "../Helper/ApiConstants";
import moment from 'moment';

import { useNavigate,useParams  } from "react-router-dom";


function TimeSlot({ time, isAvailable, isSelected, isDisabled, onSelect }) {
	const handleClick = () => {
		if (isAvailable && !isDisabled) {
			onSelect(time);
		}
	};

	return (
		<button
			className = {`btn btn-light ${isAvailable ? 'available' : 'unavailable'} ${isSelected ? 'selected' : ''}`}
			disabled = {!isAvailable || isDisabled}
			onClick = {handleClick}	>{time}</button>
	);
}

const ServiceCalender = () => {

	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedTime, setSelectedTime] = useState(null);
	const [avialbleTimeSlots, setAvialbleTimeSlots] = useState([]);

	const minSelectableDate = new Date(); // Today's date
	const maxSelectableDate = new Date(new Date().setDate(new Date().getDate() + 120)); // Today's date
	const navigate = useNavigate();

	const { business_id } = useParams();

	useEffect(() => {
		handleDateSelect( new Date( new Date().toISOString().slice(0,10)) );
	},[]);


	const handleDateSelect = (date) => {

		localStorage.setItem( business_id+'_selected_date', moment(date).format('YYYY-MM-DD') );

		setSelectedDate(date);
		setSelectedTime(null);

		var cart_id = localStorage.getItem(business_id+'_cart_id');
		var location_id = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) );

		console.log(location_id);
		var staff_id = 0;
		var store_open_time = location_id.open_timing;
		var store_close_time = location_id.close_timing;
		var data = { business_id:business_id,
						location_id: location_id.id, 
						staff_id:staff_id, 
						date:moment(date).format('YYYY-MM-DD'), 
						store_open_time:store_open_time, 
						store_close_time:store_close_time
					};

		
		axios.post(API_BASE_URL+"/getme",data)
			.then((response) => {
				console.log(response.data.time_slots);

				setAvialbleTimeSlots(response.data.time_slots);
				//set_services_data()
				//get_added_cart_addons();
				//console.log("sdfsdfasdfasd");
				
			});


	};

	const handleBookingConfirmation = () => {



		// localStorage.setItem( business_id+'_slected_date_time', JSON.stringify(
		// 														{ selectedTime: selectedTime,
		// 														selectedDate: moment(selectedDate).format('YYYY-MM-DD')
		// 														}) );

		//alert();
		//console.log(selectedTime +"==="+selectedDate.toDateString()+"----"+moment(selectedDate).format('YYYY-MM-DD'));


		//var cart_id = localStorage.getItem(business_id+'_cart_id');

		//var location_id = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) );
		//var staff_id = 0;

		// var data = { business_id:business_id,
		// 				location_id: location_id.id, 
		// 				staff_id: staff_id, 
		// 				cart_id: cart_id,
		// 				selectedTime: selectedTime, 
		// 				selectedDate: moment(selectedDate).format('YYYY-MM-DD'),
		// 			};

		// axios.post(API_BASE_URL+"/getme1",data)
		// 	.then((response) => {
		// 		//console.log(response.data.time_slots);
		// 		//setAvialbleTimeSlots(response.data.time_slots);
		// 		//set_services_data()
		// 		//get_added_cart_addons();
		// 		//console.log("sdfsdfasdfasd");
				
		// 	});
	}

	const handleTimeSlotSelect = (time) => {
		//alert(time);
		setSelectedTime(time);

		localStorage.setItem( business_id+'_selected_time', time );


	};

	// const availableTimes = [
	// 							{ date: '2023-04-30', time: '10:00 AM', isAvailable: true },
	// 							{ date: '2023-04-30', time: '11:00 AM', isAvailable: true },
	// 							{ date: '2023-04-30', time: '12:00 PM', isAvailable: true },
	// 							{ date: '2023-04-31', time: '01:00 PM', isAvailable: false },
	// 							{ date: '2023-04-31', time: '02:00 PM', isAvailable: true },
	// 							{ date: '2023-04-31', time: '03:00 PM', isAvailable: false },
	// 							{ date: '2023-04-09', time: '04:00 PM', isAvailable: true },
	// 							{ date: '2023-04-09', time: '05:00 PM', isAvailable: true },
	// 						];
	// console.log("ddddddd==",moment(selectedDate).format('YYYY-MM-DD'))

	// const availableTimesForSelectedDate = availableTimes.filter(

	// 	(time) => (time.date === moment(selectedDate).format('YYYY-MM-DD'))

	// );


	// const availableTimesForSelectedDate = avialbleTimeSlots.filter(

	// 	(time) => (time.date === moment(selectedDate).format('YYYY-MM-DD'))

	// );


	//console.log(availableTimesForSelectedDate,'ppppppp');

	return (
		<>
			<div>Service Calender</div>

			<div className="calendar-container time-parts">
				<Calendar 
						value		= { selectedDate } 
						onChange	= { handleDateSelect } 
						minDate 	= { minSelectableDate } 
						maxDate 	= { maxSelectableDate }
					
					/>
			</div>

			<div className="TimeSlot time-parts">
			<h1>Seleselect time</h1>

				{ avialbleTimeSlots.length > 0 && (
					<div className="time-slots">
						{avialbleTimeSlots.map((time) => (
							<TimeSlot
								key={time.time}
								time={time.time}
								isAvailable={time.isAvailable}
								isSelected={time.time === selectedTime}
								isDisabled={!time.isAvailable}
								onSelect={handleTimeSlotSelect}
							/>
						))}
					</div>
				)}

				{ selectedTime && (
					<div className="booking-confirmation">
						<p>You have selected {selectedTime} on {selectedDate.toDateString()}.</p>
						{/* Add a form here to collect additional information */}
						<button className="btn btn-warning" onClick={ () => setSelectedTime(null)} >Cancel</button>
						<button className="btn btn-success"  onClick={() => navigate('/booking/business/'+business_id+'/booking-review')} >Confirm</button>
					</div>
				)}

				{/*{ availableTimesForSelectedDate.length === 0 && (
					<p>Sorry, there are no available time slots for this date.</p>
				)}*/}

			</div>
		</>
	)
}

export default ServiceCalender