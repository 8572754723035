import React, { useEffect } from "react";
//import axios from "axios";
import { useNavigate,useParams  } from "react-router-dom";

//import { Capitalize } from '../Helper/Helper'
import { useForm } from "react-hook-form";

//import { API_BASE_URL } from "../Helper/ApiConstants";

const Checkout = () => {

	const { business_id } = useParams();
	//const [cart_items, setCartItems] = useState([]);
	//const [cart_total, setCartTotal] = useState('');
	//const [location_name, setLocationName] = useState('');

	//const [booking_date, setBookingDate] = useState('');
	//const [booking_time, setBookingTime] = useState('');
	//const [visit_type, setVisitType] = useState('');

	const {
		//register,
		handleSubmit,
		//reset,
		//control,
		//getValues,
		setValue,
		formState: {  },
	} = useForm({
		mode: "onChange",
	});
	
	const navigate = useNavigate();
	// const navigate_to_menu = () =>{


	// }


	// const get_cart_services = ()=> {

	// 	// var cart_id = localStorage.getItem(business_id+'_cart_id');
	// 	// var location_id = JSON.parse( (localStorage.getItem(business_id+'_selected_location')) );

	// 	// var data = { cart_id:cart_id,location_id:location_id.id };

	// 	// axios.post(API_BASE_URL+"/get-added-cart-services",data)
	// 	// 	.then((response) => {

	// 	// 		//console.log(response.data.cart_services);
	// 	// 		setCartItems(response.data.cart_services);
	// 	// 		setCartTotal(response.data.sum);
	// 	// 		localStorage.setItem( business_id+'_cart_items', JSON.stringify(response.data.cart_services) );

	// 	// 	});


	// }


	const updateHandler = (data1) => {


	};

	// const remove_cart_added_service = (id) =>{

	// 	//var data = { addon_id:addon_id,cart_id:cart_id,addon_service_id:decode(service_id) };

	// 	axios.get(API_BASE_URL+"/remove-cart-service/"+id)
	// 		.then((response) => {
	// 			get_cart_services();
	// 			//set_services_data()
	// 			//get_added_cart_addons();
	// 			//console.log("sdfsdfasdfasd");
				
	// 		});
	// }

	// const service_Staff_member = (staff_member) => {

	// 	//console.log(staff_member['first_name'],"-------------============");
	// 	var staff_member_name = 'Any First Available';
		
	// 	if(staff_member) {
	// 		staff_member_name = staff_member['first_name'] +' '+  staff_member['last_name'];
	// 	}

	// 	return staff_member_name;

	// }
		console.log(";;;;;;;;;");

	useEffect(() => {

		var loggedin_user_info = JSON.parse( (localStorage.getItem('userData')) );

		console.log(loggedin_user_info);
		// setBookingDate(localStorage.getItem(business_id+'_selected_date'));
		// setBookingTime(localStorage.getItem(business_id+'_selected_time'));


		// setVisitType( localStorage.getItem(business_id+'_visit_type') );

		// setLocationName(location_name1);

		//get_cart_services();
		
	},[]);


  return (
	<>
	<div className="back-lnk">
		<div className='backGloble' onClick={() => navigate('/booking/business/'+business_id+'/menu')}>
			<i className="fas fa-chevron-left"></i>Back
		</div>
	</div>
	  
	<div className="secteitle menu animate__animated animate__fadeInRight">
		
		<div className="addons">
			<div className="addons-right"> Confirm Your Bookings</div>
		</div>
		<div className="checkboxforfj">

			<form onSubmit={handleSubmit(updateHandler)} method="POST">
				<div className="row">
					<div className="col">
						<div className="form-group">
								<label htmlFor="card_holder_name">Card Holder Name</label>
							<input type="text" className="form-control" id="card_holder_name" placeholder="Card Holder Name" />
						</div>

						
					</div>

					<div className="col">
						<div className="form-group">
							<label htmlFor="card_no">Card No.</label>
							<input type="text" className="form-control" id="card_no" placeholder="Card No" />
						</div>
					</div>
					
				</div>
				<div className="row">
					<div className="col">
						<input type="text" className="form-control" id="card_no" placeholder="Exp Month" />
					</div>
					<div className="col">
						<input type="text" className="form-control" id="card_no" placeholder="Exp Year" />
					</div>
					<div className="col">
						<input type="text" className="form-control" id="card_no" placeholder="CVV Code" />
					</div> 

					<div className="col">
						<input type="text" className="form-control" id="card_no" placeholder="Zip Code" />
					</div>
				</div>
				<div className="back-lnk">
					<br />
					<button type="submit" className="btn btn-success">Confirm</button>
				</div>
			</form>
		</div>
	</div>
	</>
  )
}

export default Checkout